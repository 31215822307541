<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-tabs class="mb-3">
        <v-tab @click="loadRow(false)">Активные</v-tab>
        <v-tab @click="loadRow(true)">Не активные</v-tab>
      </v-tabs>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :single-select="true"
        :items-per-page="10"
        loading-text="Идёт загрузка данных..."
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :footer-props="footerProps"
        style="cursor: pointer"
      >
        <template v-slot:[`item.index`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="!deleted" small class="mr-2" @click="editName(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="!deleted"
            small
            class="mr-2"
            color="red"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="deleted"
            small
            class="mr-2"
            color="success"
            @click="deleteItem(item)"
          >
            mdi-power
          </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="create"> Добавить </v-btn>
    </v-card>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline"> Название </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="foodName.name"
            label="Введите название"
            dense
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Назад </v-btn>
          <v-btn color="primary" text @click="saveFood" :disabled="disabledBtn">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subplacedialog" max-width="600">
      <v-card>
        <v-card-title class="headline"> Редактировать название </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="foodName.name"
            label="Введите новое название"
            dense
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="subplacedialog = false">
            Отмена
          </v-btn>
          <v-btn
            color="primary"
            @click="newFoodName"
            text
            :disabled="disabledBtn"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    disabledBtn: false,
    foodName: {
      id: null,
      name: null
    },
    overlay: false,
    dialog: false,
    subplacedialog: false,
    selected: [],
    loading: true,
    headers: [
      { text: "№", value: "index", class: "table-title" },
      {
        text: "Название",
        value: "name",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Редактировать",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    items: [],
    footerProps: {
      "items-per-page-options": [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "30",
          value: 30
        },
        {
          text: "Все",
          value: 99999999999
        }
      ]
    },
    options: {},
    firstWatcherTrigger: false,
    deleted: false
  }),
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    this.loadRow();
  },
  methods: {
    async deleteItem(item) {
      try {
        await window.axios.delete(`/api/v2/booking/expenditure/${item.id}/`);
        this.loadRow();
      } catch (e) {
        console.log(e);
      }
    },
    async loadRow(value = null) {
      this.loading = true;
      if (value !== null) {
        this.deleted = value;
      }
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        is_active: !this.deleted
      };
      this.items = (
        await window.axios.get(`/api/v2/booking/expenditure/`, {
          params
        })
      ).data;
      console.log(this.items);
      this.loading = false;
    },
    editName(item) {
      this.foodName = item;
      this.subplacedialog = true;
    },
    async newFoodName() {
      this.disabledBtn = true;
      console.log(localStorage.getItem("user"));
      await window.axios.patch(
        `/api/v2/booking/expenditure/${this.foodName.id}/`,
        {
          name: this.foodName.name
        }
      );
      this.disabledBtn = false;
      this.subplacedialog = false;
      this.loadRow();
    },
    async saveFood() {
      this.disabledBtn = true;
      await window.axios.post(`/api/v2/booking/expenditure/`, {
        name: this.foodName.name
      });
      this.disabledBtn = false;
      this.dialog = false;
      this.loadRow();
    },
    create() {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
